<template>
  <div>
    <div class="models">
      <!-- <img src="/static/logo.png" mode="widthFix"></img> -->
      <div class=""></div>
    </div>
    <div class="tops flex-center">
      <img src="@/assets/static/logo.png" />
      <div class="">飞银惠生活APP</div>
    </div>
    <div class="content">
      <div class="btn flex-row align-center" @click="btn(1)">
        <img class="logo" src="@/assets/static/android.png" />
        <div class="btn_text flex-center">安卓下载</div>
      </div>
      <div class="btn flex-row align-center" @click="btn(2)">
        <img class="logo" src="@/assets/static/ios.png" />
        <div class="btn_text flex-center">IOS下载</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      android: "https://xdys.img.fypay-xm.com/statics/downLoadLink/fyk.apk",
    };
  },
  mounted() {
    this.$axios
      .get(
        "/mall-user/api/user/getVersion",
        {
          headers: {
            sourcetype: "2",
            shopid: "1",
            tenantid: "1",
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      )
      .then((res) => {
        if (res.data.data.downloadLink && res.data.code == 200)
          this.android = res.data.data.downloadLink;
      })
      .catch((err) => {
        this.$toast.fail("出错了，检查网络是否正常");
      });
  },
  methods: {
    btn(e) {
      if (e == 1) {
        document.location.href = this.android;
      } else {
        document.location.href =
          "https://apps.apple.com/cn/app/%E9%A3%9E%E9%93%B6%E5%8D%A1/id6444297540";
      }
    },
  },
};
</script>

<style>
.models {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* z-index: -1; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.models div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* backdrop-filter: blur(30px); */
}

.models img {
  width: 90vw;
}

page {
  /* background-color: #F2F2F2; */
  /* width: 100vw; */
  /* height: 100vh; */
  /* background-img: linear-gradient(to top,#E31802,#fff); */
}

.logo {
  width: 20px;
  height: 20px;
}

.btn {
  box-shadow: #b21016 5rpx 5rpx 5rpx;
  margin-bottom: 15px;
  background-color: #e31802;
  /* background-img: linear-gradient(to right bottom , #E31802,#fff); */
  width: 200px;
  height: 50px;
  border-radius: 10px;
}

.btn_text {
  color: white;
  font-size: 16px;
  margin-left: 10px;
}

.tops {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40vh;
  background-color: #e31802;
  clip-path: ellipse(95% 50% at 50% 35%);
  margin-bottom: 20vh;
}

.tops img {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: #a62523 5rpx 5rpx 5rpx;
  width: 80px;
}

.tops div {
  color: white;
  margin-top: 10px;
  font-size: 18px;
}

.content,
.content div {
  z-index: 99;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.align-center {
  align-items: center;
  justify-content: center;
}
</style>
